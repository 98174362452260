import { Grid, TextField } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Dispatch, FormEvent } from "react";
import { Action, State } from "./hooks/useCreateUserReducer";
import { useQueryGetGroupsList } from "./hooks/usersHooks";
import UserGroups from "./UserGroups";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      margin: theme.spacing(2, 0, 1, 0)
    }
  })
);

interface AddUserFormProps {
  state: State;
  dispatch: Dispatch<Action>;
  onSubmit: (event: FormEvent) => void;
}

export default function AddUserForm({
  state,
  dispatch,
  onSubmit
}: AddUserFormProps) {
  const classes = useStyles();
  const { data, loading: loadingGroups } = useQueryGetGroupsList();
  const groupNames =
    data?.accessManagement.groups.map(group => group.name) ?? [];

  return (
    <form id="userForm" onSubmit={onSubmit}>
      <Grid container direction="column">
        <TextField
          id="first-name"
          label="First Name"
          name="first-name"
          inputProps={{ maxLength: 35, "data-testid": "first-name" }}
          value={state.firstName}
          required
          onChange={e => {
            const value = e.target.value;
            dispatch({ type: "SET_FIRST_NAME", value });
          }}
          margin="normal"
          variant="outlined"
        />

        <TextField
          id="last-name"
          label="Last Name"
          name="last-name"
          inputProps={{ maxLength: 35, "data-testid": "last-name" }}
          fullWidth
          value={state.lastName}
          required
          onChange={e => {
            e.persist();
            const value = e.target.value;
            dispatch({ type: "SET_LAST_NAME", value });
          }}
          margin="normal"
          variant="outlined"
        />

        <TextField
          id="email"
          label="Email"
          name="email"
          inputProps={{ maxLength: 256, "data-testid": "email" }}
          required
          value={state.email}
          onChange={e => {
            const value = e.target.value;
            dispatch({ type: "SET_EMAIL", value });
          }}
          margin="normal"
          variant="outlined"
          type="email"
        />

        <Grid item className={classes.select}>
          <UserGroups
            userGroups={state.groups}
            userName={state.email}
            allGroups={groupNames}
            loadingGroups={loadingGroups}
            onUserGroupAdded={(_, group) => {
              dispatch({
                type: "ADD_GROUP",
                value: group
              });
            }}
            onUserGroupRemoved={(_, group) => {
              dispatch({
                type: "REMOVE_GROUP",
                value: group
              });
            }}
            label="Groups"
            height={56}
          />
        </Grid>
      </Grid>
    </form>
  );
}
