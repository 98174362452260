import { useQuery, gql } from "@apollo/client";
import {
  NextVersionInfo,
  NextVersionInfoVariables,
  NextVersionInfo_cloudPlatform_useCase_detail_nextVersion_schema,
  NextVersionInfo_cloudPlatform_useCase_detail_nextVersion_schemaDiff
} from "./schema/NextVersionInfo";

export type NextVersionInfoProps = NextVersionInfoVariables;
export type SchemaDiff = NextVersionInfo_cloudPlatform_useCase_detail_nextVersion_schemaDiff;
export type Schema = NextVersionInfo_cloudPlatform_useCase_detail_nextVersion_schema;

const queryNextVersionInfo = gql`
  query NextVersionInfo(
    $calculationRuleAddress: String!
    $useCaseKey: String!
  ) {
    cloudPlatform {
      useCase(useCaseKey: $useCaseKey) {
        detail {
          nextVersion(calculationRuleAddress: $calculationRuleAddress) {
            releaseType
            version
            schema {
              input {
                columns {
                  nullable
                  name
                  typeName
                }
                name
              }
              output {
                columns {
                  name
                  nullable
                  typeName
                }
                name
              }
            }
            schemaDiff {
              input {
                changedTables {
                  name
                  changedColumns {
                    from {
                      name
                      nullable
                      typeName
                    }
                    to {
                      name
                      nullable
                      typeName
                    }
                  }
                  missingColumns {
                    name
                    nullable
                    typeName
                  }
                  newColumns {
                    name
                    nullable
                    typeName
                  }
                }
                missingTables {
                  name
                  columns {
                    name
                    nullable
                    typeName
                  }
                }
                newTables {
                  name
                  columns {
                    name
                    nullable
                    typeName
                  }
                }
              }
              output {
                changedTables {
                  name
                  changedColumns {
                    from {
                      name
                      nullable
                      typeName
                    }
                    to {
                      name
                      nullable
                      typeName
                    }
                  }
                  missingColumns {
                    name
                    typeName
                    nullable
                  }
                  newColumns {
                    name
                    nullable
                    typeName
                  }
                }
                missingTables {
                  name
                  columns {
                    name
                    nullable
                    typeName
                  }
                }
                newTables {
                  name
                  columns {
                    name
                    nullable
                    typeName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function useQueryChangePreview(
  variables: NextVersionInfoVariables
) {
  return useQuery<NextVersionInfo, NextVersionInfoVariables>(
    queryNextVersionInfo,
    {
      variables
    }
  );
}
