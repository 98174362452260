import { CircularProgress, Grid, Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { AccessTime, ErrorOutline } from "@mui/icons-material";
import BasicCard from "components/card/BasicCard";
import { useWorkspaceName } from "features/workspace";
import { DeepOmit } from "utils/typescript";
import { Projects_projects_projects } from "./hooks/schema/Projects";

export type Project = DeepOmit<Projects_projects_projects, "__typename">;

const useStyles = makeStyles(theme => ({
  boldText: {
    fontWeight: 600,
    marginLeft: theme.spacing(1),
    fontSize: 14
  },
  secondaryText: {
    marginLeft: theme.spacing(1),
    fontSize: 14
  },
  flex: {
    display: "flex",
    alignItems: "center"
  },
  processing: {
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center"
  },
  waiting: {
    color: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center"
  },
  error: {
    color: "#b4015b",
    display: "flex",
    alignItems: "center"
  },
  icon: {
    height: 36,
    width: 36,
    margin: theme.spacing(0, 1, 1, 0)
  },
  margin: { margin: theme.spacing(0, 1, 1, 0) }
}));

export default function ProjectCard({ project }: { project: Project }) {
  const { id, name, description, image, state, tags, databaseName } = project;
  const { data: workspace } = useWorkspaceName({
    projectId: id
  });

  const workspaceName = workspace?.projects.project?.workspace?.name;
  const classes = useStyles();
  return (
    <Grid item data-testid="project-card">
      <BasicCard
        disabled={false}
        name={name}
        subtitle={workspaceName}
        icon={image}
        tags={tags}
        linkTo={`./project/${id}`}
        description={description}
      >
        <div className={classes.flex}>
          {state === "Scheduled" ? (
            <div className={classes.waiting}>
              <Tooltip
                title={
                  <div>
                    <Typography className={classes.boldText}>
                      Waiting...
                    </Typography>
                    <Typography className={classes.secondaryText}>
                      Another database is already in progress, only one database
                      can be created at a time
                    </Typography>
                  </div>
                }
              >
                <AccessTime className={classes.icon} />
              </Tooltip>
            </div>
          ) : state === "InProgress" ? (
            <div className={classes.processing}>
              <Tooltip
                title={
                  <div>
                    <Typography className={classes.boldText}>
                      In progress...
                    </Typography>
                    <Typography className={classes.secondaryText}>
                      {`The ${databaseName} database will be created, it can take a while`}
                    </Typography>
                  </div>
                }
              >
                <CircularProgress size={36} className={classes.margin} />
              </Tooltip>
            </div>
          ) : state === "Failed" ? (
            <div className={classes.error}>
              <Tooltip
                title={
                  <Typography className={classes.secondaryText}>
                    {`The ${databaseName} database could not be created, an error occurred`}
                  </Typography>
                }
              >
                <ErrorOutline className={classes.icon} />
              </Tooltip>
            </div>
          ) : null}
        </div>
      </BasicCard>
    </Grid>
  );
}
