import { useApolloClient, gql } from "@apollo/client";
import {
  QueryApiDocumentation,
  QueryApiDocumentationVariables
} from "./schema/QueryApiDocumentation";

export const apiDocumentationQuery = gql`
  query QueryApiDocumentation(
    $useCaseKey: String!
    $useCaseVersion: String
    $apiKeyId: String
  ) {
    cloudPlatform {
      useCase(useCaseKey: $useCaseKey, version: $useCaseVersion) {
        publicApi {
          apiDocumentation(apiKeyId: $apiKeyId)
        }
      }
    }
  }
`;

export const useQueryApiDocumentation = () => {
  const client = useApolloClient();
  return async (variables: QueryApiDocumentationVariables) => {
    const response = await client.query<
      QueryApiDocumentation,
      QueryApiDocumentationVariables
    >({
      query: apiDocumentationQuery,
      variables
    });
    return response.data?.cloudPlatform.useCase?.publicApi.apiDocumentation;
  };
};
