import { LinearProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import TableSet from "components/tableSetExplorer/TableSet";
import { isEmpty } from "lodash";
import { useLocation } from "react-router-dom";
import { useCalculationInputData, useQueryInputSchema } from "./inputDataHooks";

const useStyles = makeStyles(theme => ({
  tableSetWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    border: `solid 1px rgba(0,0,0,0.12)`
  },
  backgroundColor: { backgroundColor: theme.palette.primary.main }
}));

const InputDataTableSet = ({ useCaseKey, useCaseVersion }) => {
  const location = useLocation();
  const dataKey = location.state.dataKey;
  const { tableSetWrapper, backgroundColor } = useStyles({});
  const {
    data: { inputSchema, inputColumnDefs, loadingData }
  } = useQueryInputSchema({
    useCaseKey,
    useCaseVersion,
    keys: [dataKey]
  });
  const { loadInputData } = useCalculationInputData();
  return (
    <div className={tableSetWrapper}>
      {isEmpty(inputSchema) || loadingData ? (
        <LinearProgress />
      ) : (
        <TableSet
          tablesSchema={inputSchema}
          dataKey={dataKey}
          useCaseVersion={useCaseVersion}
          useCaseKey={useCaseKey}
          loadData={loadInputData}
          columnDefs={inputColumnDefs}
          backgroundColor={backgroundColor}
        />
      )}
    </div>
  );
};

export default InputDataTableSet;
