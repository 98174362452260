import { useQuery, gql } from "@apollo/client";
import {
  QueryProjectById,
  QueryProjectByIdVariables,
  QueryProjectById_projects_project
} from "./schema/QueryProjectById";

export type Project = QueryProjectById_projects_project;

export const queryProjectById = gql`
  query QueryProjectById($projectId: ID!) {
    projects {
      project(projectId: $projectId) {
        databaseName
        description
        error
        id
        image
        name
        state
        tags
        databaseTemplateId
        databaseProgress
        workspace {
          id
        }
      }
    }
  }
`;

export default function useQueryProjectById(
  variables: QueryProjectByIdVariables
) {
  return useQuery<QueryProjectById, QueryProjectByIdVariables>(
    queryProjectById,
    {
      variables,
      notifyOnNetworkStatusChange: true
    }
  );
}
