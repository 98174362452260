import { useQuery, gql } from "@apollo/client";
import { UseCaseManagementOverview } from "./schema/UseCaseManagementOverview";

export const queryUseCaseManagementList = gql`
  query UseCaseManagementOverview {
    cloudPlatform {
      useCases(latestVersionOnly: false) {
        detail {
          key
          name
          version
          description
          tags
        }
      }
    }
  }
`;

export const useUseCaseManagementOverview = () =>
  useQuery<UseCaseManagementOverview>(queryUseCaseManagementList);
