import { useQuery, gql } from "@apollo/client";
import { ProjectName, ProjectNameVariables } from "./schema/ProjectName";
import { Projects } from "./schema/Projects";

const projectsList = gql`
  query Projects {
    projects {
      projects {
        databaseName
        description
        id
        image
        name
        tags
        state
        error
      }
    }
  }
`;

const queryProjectName = gql`
  query ProjectName($projectId: ID!) {
    projects {
      project(projectId: $projectId) {
        id
        name
      }
    }
  }
`;

export const useProjectsDataWithPolling = () => {
  const { data, loading, error } = useQuery<Projects>(projectsList, {
    pollInterval: 30000
  });

  return { data: data?.projects.projects, loading, error };
};

export const useProjectName = (variables: ProjectNameVariables) =>
  useQuery<ProjectName>(queryProjectName, {
    variables,
    fetchPolicy: "cache-first"
  });
