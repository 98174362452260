import { difference } from "lodash";
import { AtLeastOnePermission } from "./RestrictedRealm";
import usePermissionsData from "./usePermissionsData";

export default function useRequestUserPermissions(
  requestedPermissions: AtLeastOnePermission
) {
  const { data: permissionsData, ...queryResult } = usePermissionsData();

  const userPermissions = permissionsData?.accessManagement.me?.permissions;
  const missingPermissions =
    userPermissions !== undefined
      ? difference(requestedPermissions, userPermissions)
      : undefined;
  const accessGranted = missingPermissions?.length === 0;

  const data = {
    missingPermissions,
    accessGranted
  };

  return {
    ...queryResult,
    data
  };
}
