import { LinearProgress } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { History } from "history";
import { observer } from "mobx-react-lite";
import { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import { useAuthenticator } from "@aws-amplify/ui-react";
import { RestrictedRealmContextProvider } from "features/access-management";
import { RouteModule, Title } from "pages/Routes";
import CustomGreetings from "./authenticator-components/CustomGreetings";
import InvalidRoute from "./InvalidRoute";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#F5F7F7",
    flex: 1,
    overflow: "overlay", // scrollbar will overlay in the padding of the main div if necessary
    display: "flex",
    flexDirection: "column"
  },
  main: {
    padding: theme.spacing(3),
    flex: "1 1 auto",
    minWidth: 480,
    overflow: "auto"
  }
}));

const Shell = ({
  routes,
  history
}: {
  routes: RouteModule[];
  history: History;
}) => {
  const { root, main } = useStyles({});
  const debug = localStorage.getItem("debug") === "true";
  const { authStatus, signOut, user } = useAuthenticator();
  return (
    <RestrictedRealmContextProvider debug={debug}>
      <CustomGreetings authState={authStatus} signOut={signOut} user={user} />
      <div className={root}>
        {/* The MaintenanceBanner should be displayed here */}
        <main className={main}>
          <Suspense fallback={<LinearProgress />}>
            <Switch>
              {routes.map(({ path, title, View }, index) => (
                <Route key={index} exact path={path}>
                  <Title title={title}>
                    <View />
                  </Title>
                </Route>
              ))}
              <Route path="/" component={InvalidRoute} />
            </Switch>
          </Suspense>
        </main>
      </div>
    </RestrictedRealmContextProvider>
  );
};

export default observer(Shell);
