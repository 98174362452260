import { isEmpty } from "lodash";
import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

export function useSearchQueryParam(
  param: string,
  defaultValue: string = ""
): [string, (value: string) => void] {
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location]
  );
  const paramSetState = (value: string) => {
    value === defaultValue || isEmpty(value)
      ? urlSearchParams.delete(param)
      : urlSearchParams.set(param, value);

    history.replace({
      ...location,
      search: urlSearchParams.toString()
    });
  };
  return [urlSearchParams.get(param) || defaultValue, paramSetState];
}
