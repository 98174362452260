import { Button, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { createRoute } from "utils/url";
import { UseCaseManagementOverview_cloudPlatform_useCases } from "./hooks/schema/UseCaseManagementOverview";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(1)
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: 0,
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  }
}));

interface UseCaseToolPanelProps {
  history: any;
  selectedRow?: UseCaseManagementOverview_cloudPlatform_useCases;
  setOpenRemoveUserDialog: (value: boolean) => void;
}

const UseCaseToolsPanel = ({
  history,
  selectedRow,
  setOpenRemoveUserDialog
}: UseCaseToolPanelProps) => {
  const classes = useStyles();

  return !selectedRow ? (
    <Typography variant="subtitle1">Select a use case</Typography>
  ) : (
    <div className={classes.root}>
      <Button
        data-testid="open-use-case-button"
        size="small"
        variant="contained"
        className={classes.button}
        onClick={() => {
          history.push(
            createRoute(
              `/use-cases/${selectedRow.detail.key}/${selectedRow.detail.version}`
            )
          );
        }}
      >
        Open Use Case
      </Button>
      <Button
        data-testid="create-new-version-button"
        size="small"
        variant="contained"
        className={classes.button}
        onClick={() => {
          history.push(
            createRoute(
              `/use-case-manager/use-case/${selectedRow.detail.key}@${selectedRow.detail.version}/create`
            )
          );
        }}
      >
        Add new version
      </Button>
      <Button
        size="small"
        variant="contained"
        className={classes.button}
        onClick={() => setOpenRemoveUserDialog(true)}
      >
        Delete use case
      </Button>
    </div>
  );
};
export default UseCaseToolsPanel;
