import { IconButton, Tooltip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import LogoutIcon from "@mui/icons-material/ExitToApp";

const useStyles = makeStyles(() => ({
  cell: {
    display: "flex",
    alignItems: "center",
    height: "100%"
  }
}));

export default function SessionActions(params: {
  userName: string;
  handleExpireSession: (userName: string) => void;
  canExpireSession: boolean;
}) {
  const { userName, handleExpireSession, canExpireSession } = params;
  const classes = useStyles();

  return (
    <Tooltip title="Expire session">
      <span>
        <IconButton
          className={classes.cell}
          disabled={!canExpireSession}
          onClick={() => {
            handleExpireSession(userName);
          }}
          size="large">
          <LogoutIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
}
