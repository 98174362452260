import React from "react";
import APIDetailView from "./APIDetailView";

const PublicApi: React.FunctionComponent<{
  useCaseKey: string;
  useCaseVersion: string;
}> = ({ useCaseKey, useCaseVersion }) => {
  return (
    <APIDetailView useCaseKey={useCaseKey} useCaseVersion={useCaseVersion} />
  );
};

export default PublicApi;
