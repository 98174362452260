import { Typography } from "@mui/material";
import clsx from "clsx";
import { HTMLAttributes } from "react";
import { DeepOmit } from "utils/typescript";
import { Workspace_projects_workspace_capacity as Capacity } from "../schema/Workspace";
import CapacityLegend from "./CapacityLegend";
import { useStyles } from "./CapacityStatusStyle";
import CapacityValues from "./CapacityValues";

export type CapacityStatusProps = {
  capacity: DeepOmit<Capacity, "__typename">;
  className?: HTMLAttributes<HTMLDivElement>["className"];
};

export default function CapacityStatus({
  capacity,
  className
}: CapacityStatusProps) {
  const classes = useStyles();
  const computeCapacityStatus = capacity.computeCapacityStatus;

  const pending = computeCapacityStatus.desired - computeCapacityStatus.running;
  const onStandBy = capacity.maxCapacity - computeCapacityStatus.desired;
  const maxLimit =
    capacity.maxCapacity >= computeCapacityStatus.available
      ? capacity.maxCapacity
      : computeCapacityStatus.available;
  return (
    <div className={clsx(className, classes.grid)}>
      <Typography variant="h5" className={classes.header}>
        Seats status
      </Typography>

      <div className={classes.status}>
        <div className={classes.max}></div>
        <div
          className={classes.pending}
          style={{
            transform: `translateY(${
              100 -
              ((computeCapacityStatus.inUse +
                computeCapacityStatus.available +
                pending) *
                100) /
                maxLimit
            }%)`,
            zIndex: pending >= 0 ? 0 : 1,
            backgroundColor: pending >= 0 ? "#f1d797" : "#ffb900"
          }}
        ></div>

        <div
          className={classes.free}
          style={{
            transform: `translateY(${
              100 -
              ((computeCapacityStatus.available + computeCapacityStatus.inUse) *
                100) /
                maxLimit
            }%)`,
            background:
              pending < 0
                ? ` repeating-linear-gradient(
              45deg,
              transparent,
              transparent 10px,
              #f1d797 10px,
              #f1d797 20px
            ),
            
            linear-gradient(
              to bottom,
              #ffb900,
              #f1d797
            )`
                : "#ffb900"
          }}
        ></div>

        <div
          className={classes.taken}
          style={{
            transform: `translateY(${
              100 - (computeCapacityStatus.inUse * 100) / maxLimit
            }%)`
          }}
        ></div>
      </div>
      <CapacityValues
        min={capacity.minCapacity}
        max={capacity.maxCapacity}
        desired={computeCapacityStatus.desired}
        maxLimit={maxLimit}
      />
      <div className={classes.legends}>
        <CapacityLegend
          title="On standby"
          color="yellow"
          className={classes.onStandByLegend}
          tooltip="The remaining seats, that can be added by a scale-up event"
        >
          {onStandBy}
        </CapacityLegend>
        <CapacityLegend title="Pending" color="yellow">
          {computeCapacityStatus.desired - computeCapacityStatus.running}
        </CapacityLegend>
        <CapacityLegend title="Free" color="secondary">
          {computeCapacityStatus.available}
        </CapacityLegend>
        <CapacityLegend title="Taken" color="primary">
          {computeCapacityStatus.inUse}
        </CapacityLegend>
      </div>
    </div>
  );
}
