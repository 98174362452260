import { Button, Tooltip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Link from "components/base/Link";
import { Permission, RestrictedRealm } from "features/access-management";

const useStyles = makeStyles(theme => ({
  link: {
    marginLeft: "auto",
    textDecoration: "none"
  },
  button: {
    color: theme.palette.common.white
  }
}));

export default function EditProjectButton({
  projectId
}: {
  projectId: string;
}) {
  const classNames = useStyles();
  const linkToEditProject = `/projects/project/${projectId}/edit`;

  return (
    <RestrictedRealm requiredPermissions={[Permission.ChangeProjects]} silent>
      <Link to={linkToEditProject} className={classNames.link}>
        <Tooltip title="Edit project metadata">
          <Button
            color="primary"
            data-testid="edit-project"
            variant="contained"
            className={classNames.button}
          >
            Edit
          </Button>
        </Tooltip>
      </Link>
    </RestrictedRealm>
  );
}
