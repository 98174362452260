import { Chip } from "@mui/material";
import { useProjectName } from "features/project";
import { createRoute } from "utils/url";

export default function AssociatedProject({
  projectId
}: {
  projectId: string;
}) {
  const { data: project } = useProjectName({ projectId });
  const projectName = project?.projects.project?.name;
  return (
    <Chip
      label={projectName}
      component="a"
      href={createRoute(`/projects/project/${projectId}`)}
      clickable
    />
  );
}
