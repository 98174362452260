import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

export const dropzoneStyles = (theme: Theme) =>
  createStyles({
    // TODO remove dropzoneHack after https://github.com/Yuvaleros/material-ui-dropzone/issues/107 is resolved
    dropzoneHack: {
      backgroundImage: "none !important"
    },
    dropzoneBorderColor: {
      borderColor: "#B1B1B1"
    },
    dropzoneErrorBorder: {
      borderColor: theme.palette.error.main
    },
    dropzoneClass: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      backgroundColor: "#F5F7F7",
      borderRadius: 4,
      minHeight: 175,
      height: 175,
      "&:focus-within": {
        borderColor: theme.palette.primary.main
      },
      "&:focus": {
        borderColor: theme.palette.primary.main,
        outline: "none"
      }
    },
    uploadCompleted: {
      "&>div": {
        "&:nth-child(1)": {}
      }
    },
    uploadUncompleted: {
      "&>div": {
        "&:nth-child(1)": {
          "&>svg": {
            color: "#C7CFD4"
          }
        }
      }
    },
    textContainer: {
      display: "flex",
      flexDirection: "column-reverse",
      alignItems: "center"
    },
    text: {
      fontSize: 16,
      color: "#B1B1B1",
      margin: theme.spacing(0, 1, 1, 1)
    },
    uploadCompletedIcon: {
      height: 41,
      width: 41,
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(1)
    },
    uploadUncompletedIcon: {
      height: 41,
      width: 41,
      color: "#B1B1B1",
      marginBottom: theme.spacing(1)
    },
    gridContainer: {
      position: "absolute",
      bottom: theme.spacing(1),
      left: theme.spacing(1),
      width: `calc(100% - ${theme.spacing(1)})`,
      paddingRight: theme.spacing(1),
      "&>div": {
        "&:first-child": {
          maxWidth: "100%"
        }
      }
    },
    maxWidth: { maxWidth: "100%" }
  });

export default makeStyles((theme: Theme) =>
  createStyles({
    iconPlaceholder: {
      height: 20
    },
    fileUploadProgress: {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 1200,
      width: "50px !important",
      height: "50px !important"
    },
    relative: {
      position: "relative"
    },
    adjoin: {
      width: "48%"
    },
    helperText: {
      color: "#788791"
    },
    label: {
      color: "#788791"
    },
    notchedOutline: {
      borderColor: "#cdd9e1"
    },
    button: {
      textTransform: "capitalize",
      color: theme.palette.common.white,
      marginLeft: theme.spacing(1)
    },
    buttonPrimary: {
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: theme.palette.primary.light
      }
    },
    buttonSecondary: {
      backgroundColor: theme.palette.primary.dark,
      "&:hover": {
        backgroundColor: theme.palette.primary.main
      }
    },
    link: {
      display: "inline-flex",
      textDecoration: "none",
      alignItems: "center",
      color: "#697882"
    },
    form: { width: "100%" },
    iconComponent: { position: "relative", height: 50, width: 50 },
    ...dropzoneStyles(theme)
  })
);
