import { green, grey, red, yellow } from "@mui/material/colors";

import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

import {
  SchemaDiff_cloudPlatform_useCase_detail_schemaDiff,
  SchemaDiff_cloudPlatform_useCase_detail_schemaDiff_input,
  SchemaDiff_cloudPlatform_useCase_detail_schemaDiff_output,
  SchemaDiff_cloudPlatform_useCase_schema,
  SchemaDiff_cloudPlatform_useCase_schema_input,
  SchemaDiff_cloudPlatform_useCase_schema_output
} from "./components/schema/SchemaDiff";

import DataGridView from "components/grid/DataGridView";
import { GroupItem, Summary } from "devextreme-react/data-grid";
import { CustomSummaryInfo } from "devextreme/ui/data_grid";

interface SchemaDiffRow {
  tableCategory: "Input" | "Output";
  tableName: string;
  columnName: string;
  columnType: string | null;
  columnNullable: boolean | null;
  changeCategory:
    | "Added"
    | "Removed"
    | "Changed from"
    | "Changed to"
    | "Unchanged";
}

function notNull<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

const enrichSchemaWithDiff = (
  schema:
    | SchemaDiff_cloudPlatform_useCase_schema_input[]
    | SchemaDiff_cloudPlatform_useCase_schema_output[],
  schemaDiff:
    | SchemaDiff_cloudPlatform_useCase_detail_schemaDiff_input
    | SchemaDiff_cloudPlatform_useCase_detail_schemaDiff_output,
  tableCategory: "Input" | "Output"
) =>
  schema
    .filter(table => table.columns != null)
    .reduce((acc: SchemaDiffRow[], table) => {
      const changedTable = schemaDiff.changedTables.find(
        cT => cT.name === table.name
      );
      const tableIsNew =
        schemaDiff.newTables.find(nT => nT.name === table.name) != null;
      return acc.concat(
        table
          .columns!.filter(notNull)
          .flatMap<SchemaDiffRow>(column => {
            const changedColumn = changedTable?.changedColumns.find(
              cC => cC.to.name === column.name
            );
            if (changedColumn != null) {
              return [
                {
                  tableCategory,
                  tableName: table.name,
                  columnName: column.name,
                  columnType: changedColumn.from.typeName,
                  columnNullable: changedColumn.from.nullable,
                  changeCategory: "Changed from"
                },
                {
                  tableCategory,
                  tableName: table.name,
                  columnName: column.name,
                  columnType: changedColumn.to.typeName,
                  columnNullable: changedColumn.to.nullable,
                  changeCategory: "Changed to"
                }
              ];
            }
            const newColumn = changedTable?.newColumns.find(
              nC => nC.name === column.name
            );
            return {
              tableCategory,
              tableName: table.name,
              columnName: column.name,
              columnType: column.typeName,
              columnNullable: column.nullable,
              changeCategory:
                newColumn != null || tableIsNew ? "Added" : "Unchanged"
            };
          })
          .filter(notNull)
      );
    }, [])
    .concat(
      schemaDiff.changedTables.reduce(
        (acc: SchemaDiffRow[], changedTable) =>
          acc.concat(
            changedTable.missingColumns.map(missingColumn => ({
              tableCategory,
              tableName: changedTable.name,
              columnName: missingColumn.name,
              columnType: missingColumn.typeName,
              columnNullable: missingColumn.nullable,
              changeCategory: "Removed"
            }))
          ),
        []
      )
    )
    .concat(
      schemaDiff.missingTables.reduce(
        (acc: SchemaDiffRow[], missingTable) =>
          missingTable.columns != null
            ? acc.concat(
                missingTable.columns.filter(notNull).map(missingColumn => ({
                  tableCategory,
                  tableName: missingTable.name,
                  columnName: missingColumn.name,
                  columnType: missingColumn.typeName,
                  columnNullable: missingColumn.nullable,
                  changeCategory: "Removed"
                }))
              )
            : acc,
        []
      )
    )
    .sort((a, b) =>
      a.tableName > b.tableName
        ? 1
        : a.tableName < b.tableName
        ? -1
        : a.columnName > b.columnName
        ? 1
        : a.columnName < b.columnName
        ? -1
        : 0
    );

const useStyles = makeStyles((theme: Theme) => ({
  diffChanged: {
    backgroundColor: `${yellow[50]}`
  },
  diffAdded: {
    backgroundColor: `${green[50]}`
  },
  diffRemoved: {
    backgroundColor: `${red[50]}`
  },
  diffUnchanged: {
    backgroundColor: `${grey[50]}`
  },
  flex: { height: "100%" }
}));

const TechnicalChangelog = ({
  schemaDiff,
  schema
}: {
  schemaDiff: SchemaDiff_cloudPlatform_useCase_detail_schemaDiff;
  schema: SchemaDiff_cloudPlatform_useCase_schema;
}) => {
  const { diffAdded, diffRemoved, diffChanged, diffUnchanged, flex } =
    useStyles({});

  const data = enrichSchemaWithDiff(
    schema.input,
    schemaDiff.input,
    "Input"
  ).concat(enrichSchemaWithDiff(schema.output, schemaDiff.output, "Output"));

  let firstTimeOnly = true;
  return (
    <div id="technicalChangelogGrid" className={flex}>
      <DataGridView<SchemaDiffRow>
        columns={[
          {
            colDef: {
              caption: "table category",
              dataField: "tableCategory",
              groupIndex: 0,
              autoExpandGroup: false
            }
          },
          {
            colDef: {
              caption: "table name",
              dataField: "tableName",
              groupIndex: 1,
              autoExpandGroup: false
            }
          },
          {
            colDef: {
              caption: "column name",
              dataField: "columnName",
              width: 300
            }
          },
          {
            colDef: {
              caption: "type",
              dataField: "columnType"
            }
          },
          {
            colDef: {
              caption: "nullable",
              dataField: "columnNullable",
              allowFiltering: false,
              allowHeaderFiltering: true
            },
            render: ({ data: { columnNullable } }) => (
              <div>{columnNullable?.toString()}</div>
            )
          },
          {
            colDef: {
              caption: "change category",
              dataField: "changeCategory"
            }
          }
        ]}
        allowGrouping={true}
        onContentReady={params => {
          const rows = params.component.getVisibleRows();
          if (firstTimeOnly) {
            rows.forEach(row => {
              firstTimeOnly = false;
              if (
                row.rowType === "group" &&
                row.data?.aggregates?.[1][0] !== "Unchanged"
              ) {
                params.component.expandRow(row.key);
                firstTimeOnly = true;
              }
            });
          }
        }}
        rowData={data}
        onCellPrepared={params => {
          const changeCategory =
            params.rowType === "data"
              ? params.data?.changeCategory
              : params.data?.aggregates?.[1][0];

          switch (changeCategory) {
            case "Changed from":
            case "Changed to":
            case "Changed":
              params.cellElement.classList.add(diffChanged);
              break;
            case "Added":
              params.cellElement.classList.add(diffAdded);
              break;
            case "Removed":
              params.cellElement.classList.add(diffRemoved);
              break;
            case "Unchanged":
              params.cellElement.classList.add(diffUnchanged);
          }
        }}
      >
        <Summary
          calculateCustomSummary={(options: CustomSummaryInfo) => {
            if (options.summaryProcess === "start") {
              options.totalValue = [];
            }
            if (options.summaryProcess === "calculate") {
              if (options.totalValue?.indexOf(options.value) === -1)
                options.totalValue.push(options.value);
            }
            if (options.summaryProcess === "finalize") {
              const totalValue = options.totalValue;
              if (totalValue?.length > 1) {
                totalValue.push("Changed");
                totalValue.splice(0, totalValue?.length - 1);
              }
            }
          }}
        >
          <GroupItem
            column="tableCategory"
            summaryType="count"
            alignByColumn={true}
          />
          <GroupItem
            column="changeCategory"
            summaryType="custom"
            alignByColumn={true}
          />
        </Summary>
      </DataGridView>
    </div>
  );
};

export default TechnicalChangelog;
