import { useQuery, gql } from "@apollo/client";
import { DatabaseBackups } from "./schema/DatabaseBackups";

const databaseBackupsList = gql`
  query DatabaseBackups {
    projects {
      databaseTemplates {
        id
        name
      }
    }
  }
`;

export const useDatabaseBackups = () =>
  useQuery<DatabaseBackups>(databaseBackupsList);
