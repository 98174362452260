import { useQuery, gql } from "@apollo/client";
import { ScsVersions } from "./schema/ScsVersions";

const scsVersionsList = gql`
  query ScsVersions {
    projects {
      workspaces {
        id
        name
      }
    }
  }
`;

export const useScsVersions = () => useQuery<ScsVersions>(scsVersionsList);
