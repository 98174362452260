import { gql, useMutation } from "@apollo/client";
import { Grid, Paper, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { ErrorBoxDialog } from "components/error";
import _ from "lodash";
import { Link, useHistory } from "react-router-dom";
import { createRoute } from "utils/url";
import AddUserForm from "./AddUserForm";
import useCreateUserReducer from "./hooks/useCreateUserReducer";
import { CreateUser, CreateUserVariables } from "./schema/CreateUser";

export const createUserMutation = gql`
  mutation CreateUser(
    $name: String!
    $firstName: String!
    $lastName: String!
    $groups: [String!]
  ) {
    accessManagement {
      createUser(
        name: $name
        firstName: $firstName
        lastName: $lastName
        groups: $groups
      ) {
        name
      }
    }
  }
`;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cancelButton: {
      color: "#FFFFFF",
      marginRight: theme.spacing(1),
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.8
      }
    },
    createButton: {
      color: "#FFFFFF",
      backgroundColor: theme.palette.primary.dark,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        opacity: 0.8
      }
    },
    disabledButton: {
      backgroundColor: "#C7CFD4 !important",
      color: "#68777B !important"
    },
    link: { display: "inline-flex", textDecoration: "none" },
    paper: { padding: theme.spacing(3), width: 600 },
    title: {
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(1)
    }
  })
);

export default function AddUser() {
  const classes = useStyles();
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    groups: []
  };
  const [state, dispatch] = useCreateUserReducer(initialState);

  const history = useHistory();
  const [createUser, { error: createUserError, reset }] = useMutation<
    CreateUser,
    CreateUserVariables
  >(createUserMutation, {
    onCompleted: _ => history.push(createRoute(`/users`)),
    onError(error) {
      console.log(error);
    }
  });

  return (
    <Grid container justifyContent="center">
      <Paper className={classes.paper}>
        <Grid container direction="column">
          <Typography className={classes.title}>NEW USER</Typography>
          <AddUserForm
            state={state}
            dispatch={dispatch}
            onSubmit={e => {
              e.preventDefault();
              createUser({
                variables: {
                  name: state.email,
                  firstName: state.firstName,
                  lastName: state.lastName,
                  groups: state.groups
                }
              });
            }}
          />
          <Grid item>
            <Link className={classes.link} to={createRoute(`/users`)}>
              <Button
                color="primary"
                variant="contained"
                className={classes.cancelButton}
              >
                Cancel
              </Button>
            </Link>
            <Button
              form="userForm"
              type="submit"
              id="create-user"
              variant="contained"
              data-testid="create-user"
              disabled={
                _.isEmpty(state.email) ||
                _.isEmpty(state.firstName) ||
                _.isEmpty(state.lastName)
              }
              classes={{
                root: classes.createButton,
                disabled: classes.disabledButton
              }}
            >
              Create User
            </Button>
          </Grid>
        </Grid>
        <ErrorBoxDialog apolloError={createUserError} onClose={reset} />
      </Paper>
    </Grid>
  );
}
