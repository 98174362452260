import { LinearProgress, Paper, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AssociatedProject from "./AssociatedProject";
import { useWorkspaceAssociatedProjects } from "./workspaceHooks";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  chips: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    padding: theme.spacing(2),
    "& > *": {
      margin: theme.spacing(0.5)
    }
  }
}));

export default function AssociatedProjects({
  workspaceId
}: {
  workspaceId: string;
}) {
  const classes = useStyles({});
  const { data: projects, loading } = useWorkspaceAssociatedProjects({
    workspaceId
  });
  const associatedProjects = projects?.projects.workspace?.associatedProjects;
  return (
    <div>
      {loading && <LinearProgress />}
      <Paper className={classes.chips}>
        <Typography variant="h5" className={classes.header}>
          Used by projects
        </Typography>
        {associatedProjects?.map(id => (
          <AssociatedProject projectId={id} key={id} />
        ))}
      </Paper>
    </div>
  );
}
