import {
  Flex,
  useAuthenticator,
  withAuthenticator
} from "@aws-amplify/ui-react";
import { Grid, Link, Typography } from "@mui/material";
import { createBrowserHistory } from "history";
import getRoutes from "pages/Routes";
import { getConfigValue } from "utils/config";
import Shell from "./Shell";
import "./authenticator-components/authenticator-components.css";
import ImprintFooter from "./authenticator-components/internal/ImprintFooter";
import logo from "./authenticator-components/internal/logo_petrol.svg";

const placeholder = "";

const App = withAuthenticator(
  Shell,

  {
    formFields: {
      signIn: {
        username: {
          placeholder,
          isRequired: true,
          label: "Email Address *"
        },
        password: {
          label: "Password *",
          placeholder,
          autocomplete: "on"
        }
      },
      signUp: {
        name: {
          label: "First Name",
          isRequired: true,
          type: "string",
          order: 1,
          placeholder
        },
        family_name: {
          label: "Last Name",
          isRequired: true,
          type: "string",
          order: 2,
          placeholder
        },
        username: {
          label: "Email Address",
          isRequired: true,
          order: 3,
          type: "email",
          placeholder
        },
        email: {
          label: "Confirm Email Address",
          isRequired: true,
          order: 4,
          type: "email",
          placeholder
        },
        password: {
          label: "Password",
          isRequired: true,
          order: 5,
          type: "password",
          placeholder,
          autocomplete: "on"
        },
        confirm_password: {
          isRequired: true,
          order: 6,
          placeholder
        }
      }
    },
    components: {
      SignIn: {
        Header() {
          return (
            <Flex>
              <img src={logo} alt="logo" className="logo" />
            </Flex>
          );
        },
        Footer() {
          const { toResetPassword, toSignUp } = useAuthenticator();
          return (
            <div className="forgetPasswordFooter">
              <div className="forgetPassword">
                Forgot your password?{" "}
                <Link
                  data-test="sign-in-forgot-password-link"
                  className="link"
                  color="primary"
                  onClick={toResetPassword}
                >
                  Reset password
                </Link>
              </div>

              <span className="footerSecondaryContent">
                No account?{" "}
                <Link
                  data-test="sign-in-create-account-link"
                  className="link"
                  color="primary"
                  onClick={toSignUp}
                >
                  Create account
                </Link>
              </span>
            </div>
          );
        }
      },
      SignUp: {
        Header() {
          return (
            <Flex>
              <img src={logo} alt="logo" className="logo" />
            </Flex>
          );
        },
        Footer() {
          const { toSignIn } = useAuthenticator();
          return (
            <div className="signUpFooter">
              <div className="singInLink">
                Have an account?{" "}
                <Link
                  data-test="sign-in-forgot-password-link"
                  className="link"
                  color="primary"
                  onClick={toSignIn}
                >
                  Sign in
                </Link>
              </div>
            </div>
          );
        }
      }
    }
  }
);

const ShellWithAuthenticator = () => {
  const history = createBrowserHistory();
  const { authStatus } = useAuthenticator();
  const environment = (getConfigValue("ENVIRONMENT") || "").replace("_", " ");
  return authStatus === "authenticated" ? (
    <App routes={getRoutes()} history={history} />
  ) : (
    <div className="root">
      <div className="container">
        <Grid container className="grid">
          <Grid item xs={12} md={5} lg={4} className="left">
            <div className="leftContent">
              <App routes={getRoutes()} history={history} />
            </div>
          </Grid>
          <Grid item xs={12} md={7} lg={8} className="right">
            <div className="rightContent">
              <Typography className="appTitle" variant="h2">
                Cloud Platform
              </Typography>
              <Typography className="environment">{environment}</Typography>
              <Typography className="appDescription">
                Create, manage and monitor your Use Cases
              </Typography>
            </div>
            <Typography className="copyright">
              Copyright ©2023 Siemens
            </Typography>
          </Grid>
        </Grid>
      </div>
      <ImprintFooter />
    </div>
  );
};
export default ShellWithAuthenticator;
