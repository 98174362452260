import { useReducer } from "react";

export interface State {
  name: string;
  key: string;
  autoGeneratedKey: boolean;
  description: string;
  loadImage?: boolean;
  loadRule?: boolean;
  image?: string | null;
  calculationRuleAddress?: string;
  tags: string[];
  dropzoneKey?: boolean;
}

export type Action =
  | { type: "SET_NAME"; value: string }
  | { type: "SET_KEY"; value: string }
  | { type: "SET_DESCRIPTION"; value: string }
  | { type: "SET_TAG"; value: string }
  | { type: "DELETE_TAG"; value: string }
  | { type: "LOAD_ICON"; value: boolean }
  | { type: "LOAD_RULE"; value: boolean }
  | { type: "DROP_ICON"; value: string | ArrayBuffer | null }
  | { type: "DROP_RULE"; value: any }
  | { type: "GENERATE_KEY" }
  | { type: "RESET" };

export const generateKeyFromName = (value: string) =>
  value.replace(/[^A-Z0-9_.-]/gi, "-").toLowerCase();

export default function usePublishFormReducer(initialState: State) {
  function reducer(state: State, action: Action): State {
    switch (action.type) {
      case "SET_NAME":
        if (state.autoGeneratedKey) {
          const key = generateKeyFromName(action.value);
          return { ...state, name: action.value, key };
        }
        return { ...state, name: action.value };
      case "SET_KEY":
        return { ...state, key: action.value, autoGeneratedKey: false };

      case "SET_DESCRIPTION":
        return { ...state, description: action.value };
      case "SET_TAG":
        return { ...state, tags: [...state.tags, action.value] };
      case "DELETE_TAG":
        return {
          ...state,
          tags: state.tags.filter(tag => tag !== action.value)
        };
      case "LOAD_ICON":
        return { ...state, loadImage: action.value };
      case "LOAD_RULE":
        return { ...state, loadRule: action.value };
      case "DROP_ICON":
        const image =
          typeof action.value === "string" ? action.value : undefined;
        return { ...state, image, loadImage: false };
      case "DROP_RULE":
        return { ...state, calculationRuleAddress: action.value };
      case "GENERATE_KEY":
        const key = generateKeyFromName(state.name);
        return { ...state, key, autoGeneratedKey: true };
      case "RESET":
        return { ...initialState, dropzoneKey: !state.dropzoneKey };
      default:
        throw new Error();
    }
  }

  return useReducer(reducer, initialState);
}
