import { Check, Close } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useState } from "react";
import EditField from "../EditField";
import { UpdateCapacityVariables } from "../schema/UpdateCapacity";

interface EditCapacityFormProps {
  updateCapacity?: ({
    workspaceId,
    minCapacity,
    maxCapacity
  }: UpdateCapacityVariables) => Promise<void>;

  allowToEdit?: boolean;
  min: number;
  max: number;
  workspaceId: string;
}

const useStyles = makeStyles(() => ({
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%"
  },
  grid: {
    display: "inline-grid",
    gridTemplateColumns:
      "215px minmax(0, 1fr) 30px minmax(0, 1fr) minmax(0, 1fr)",
    alignItems: "center"
  },
  stretch: {
    gridColumn: "1 / 6"
  }
}));

export default function EditCapacityForm({
  updateCapacity,
  workspaceId,
  allowToEdit,
  min,
  max
}: EditCapacityFormProps) {
  const classes = useStyles();

  const [minCapacity, setMinCapacity] = useState<number>(min);
  const [maxCapacity, setMaxCapacity] = useState<number>(max);
  const dirty = min !== minCapacity || max !== maxCapacity;
  return (
    <div className={classes.grid}>
      <Typography className={classes.stretch}></Typography>
      <Typography>Keep desired seats between</Typography>
      <EditField
        disabled={!allowToEdit}
        onChange={event => setMinCapacity(parseInt(event.target.value))}
        value={minCapacity}
        min="0"
        max={max.toString()}
      />

      <Typography>and</Typography>
      <EditField
        disabled={!allowToEdit}
        onChange={event => setMaxCapacity(parseInt(event.target.value))}
        value={maxCapacity}
        min={min.toString()}
      />
      {allowToEdit && dirty ? (
        <div className={classes.flex}>
          <IconButton
            onClick={() => {
              updateCapacity &&
                updateCapacity({
                  workspaceId,
                  maxCapacity,
                  minCapacity
                });
            }}
            size="small"
          >
            <Check />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => {
              setMinCapacity(min);
              setMaxCapacity(max);
            }}
          >
            <Close />
          </IconButton>
        </div>
      ) : null}
    </div>
  );
}
