import _ from "lodash";

interface IUserGroups {
  groups: {
    name: string;
  }[];
}
export const getAllUserGroups = (users: IUserGroups[]): string[] => {
  const userGroupsNames = _.flattenDeep(users.map(({ groups }) => groups)).map(
    group => group.name
  );
  const uniqGroups = _.uniq(userGroupsNames);
  return _.sortBy(uniqGroups);
};
