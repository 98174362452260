import { LinearProgress } from "@mui/material";
import {
  AddProject,
  EditProject,
  ProjectName,
  Projects,
  ProjectWrapper
} from "features/project";
import {
  AllJobsResultsWrapper,
  Calculate,
  CalculationName,
  CalculationResultInputTable,
  CalculationResultOutputTable,
  InputDataTableSet,
  JobResult,
  PublicApi,
  RedirectToLatestUseCaseVersion,
  Topics,
  UploadedDataName,
  UseCaseNameAndVersion,
  UseCases,
  UseCaseVersionSelection
} from "features/use-case";
import {
  CreateUseCase,
  EditUseCase,
  UseCaseManager,
  UseCaseManagerNavigation
} from "features/use-case-manager";
import { AddUser, Users } from "features/user";
import { Workspace, WorkspaceNavigationName } from "features/workspace";
import LandingPage from "pages/root/LandingPage";
import React, { lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams, useRouteMatch } from "react-router";
import { Redirect } from "react-router-dom";
import resolvePathname from "resolve-pathname";
import { createRoute } from "utils/url";

const UploadData = lazy(
  () => import("features/use-case/topics/collect-data/UploadData")
);
const ApiTester = lazy(
  () => import("features/use-case/topics/public-api/ApiTesterContainer")
);

export type ViewProps = {};

export type NavigationProps = {
  text: string;
  Preview: React.FC<ViewProps>;
};

/**
 * Represents a route module.
 */
export type RouteModule = {
  /**
   * A path for the given module. It is matched
   * * exactly to determine wether the view is shown and
   * * partially to detect wether the navigation is shown.
   *
   * How to use [path language](https://reacttraining.com/react-router/web/api/Route/path-string-string).
   */
  path: string | string[];
  /**
   * The view that should be rendered if path matches exactly. Only one view is rendered per time.
   */
  View: React.FC<ViewProps>;
  /**
   * Each route can have its own navigation bar element which could be used to
   * display status of the view, routing options or other minified detail of the view.
   * The navigation is shown whenever its path partially matches the current URL. Many navigation elements might be rendered per time.
   *
   * Use
   * * `undefined` or omit to get default navigation element that displays the route portion as text
   * * `null` to hide the navigation element. No element is drawn for this hierarchic route portion
   * * `ExtendedRouteRenderFunc` to render a custom element. RouteProps are provided as props. In addition `textContent` (default text content of routing portion) and view (the view rendered to this routing portion) is provided.
   */
  Navigation?: React.FC<NavigationProps> | null;
  tab: number;
  /* The HTML title which can be added to the given View
   */
  title: string;
};

export const Title: React.FC<{ title?: string; children: React.ReactNode }> = ({
  title,
  children
}) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </>
  );
};

function getRelativeRoutes(): RouteModule[] {
  return [
    {
      title: "Cloud Platform",
      path: "/",
      View: () => <LandingPage />,
      Navigation: () => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          data-testid="home-button"
        >
          Cloud Platform
        </div>
      ),
      tab: 0
    },
    {
      title: "",
      path: `/workspaces`,
      View: () => {
        return <Redirect to={createRoute("/")} />;
      },
      Navigation: () => <div>Workspaces</div>,
      tab: 0
    },
    {
      title: "Workspace",
      path: "/workspaces/:workspaceId",
      View: () => {
        const { workspaceId } = useParams<{ workspaceId: string }>();
        return <Workspace workspaceId={workspaceId} />;
      },
      Navigation: () => {
        const { workspaceId } = useParams<{ workspaceId: string }>();
        return <WorkspaceNavigationName workspaceId={workspaceId} />;
      },
      tab: 2
    },
    {
      title: "Projects",
      path: "/projects",
      View: () => <Projects />,
      Navigation: () => <div>Projects</div>,
      tab: 1
    },
    {
      title: "Create Project",
      path: "/projects/create",
      View: () => <AddProject />,
      Navigation: () => (
        <div data-testid="create-new-project-nav">Create new project</div>
      ),
      tab: 2
    },

    {
      title: "Project",
      path: "/projects/project/:projectId",
      View: () => {
        const { projectId } = useParams<{ projectId: string }>();
        return <ProjectWrapper projectId={projectId} />;
      },
      Navigation: () => {
        const { projectId } = useParams<{ projectId: string }>();
        return <ProjectName projectId={projectId} />;
      },
      tab: 2
    },
    {
      title: "Edit Project",
      path: "/projects/project/:projectId/edit",
      View: () => {
        const { projectId } = useParams<{ projectId: string }>();
        return projectId ? <EditProject projectId={projectId} /> : null;
      },
      Navigation: () => <div data-testid="edit-project-nav">Edit project</div>,
      tab: 2
    },
    {
      title: "Users",
      path: "/users",
      View: () => <Users />,
      Navigation: () => <div>Users</div>,
      tab: 1
    },
    {
      title: "Create User",
      path: "/users/create",
      View: () => <AddUser />,
      Navigation: () => (
        <div data-testid="create-new-user-nav">Create new user</div>
      ),
      tab: 2
    },
    {
      title: "Use Case Manager",
      path: "/use-case-manager",
      View: () => <UseCaseManager />,
      Navigation: () => <UseCaseManagerNavigation />,
      tab: 1
    },
    {
      title: "Create Use Case",
      path: "/use-case-manager/create",
      View: () => <CreateUseCase />,
      Navigation: () => (
        <div data-testid="create-new-use-case-nav">Create new use case</div>
      ),
      tab: 2
    },
    {
      title: "Create Use Case Version",
      path: "/use-case-manager/use-case/:useCaseId/create",
      View: () => {
        const { useCaseId } = useParams<{ useCaseId: string }>();
        const [useCaseKey] = useCaseId!.split("@");
        return useCaseKey ? <EditUseCase useCaseKey={useCaseKey} /> : null;
      },
      Navigation: () => (
        <div data-testid="create-new-version-nav">Create new version</div>
      ),
      tab: 2
    },
    {
      title: "Use Cases",
      path: "/use-cases",
      View: () => <UseCases />,
      Navigation: () => <div>Use cases</div>,
      tab: 1
    },
    {
      title: "",
      path: `/use-cases/:useCaseKey`,
      View: () => {
        const { useCaseKey } = useParams<{ useCaseKey: string }>();
        const { url } = useRouteMatch();
        return (
          <RedirectToLatestUseCaseVersion url={url} useCaseKey={useCaseKey!} />
        );
      },
      Navigation: null,
      tab: 2
    },
    {
      title: "Use Case",
      path: "/use-cases/:useCaseKey/:useCaseVersion",
      View: () => {
        const { useCaseKey, useCaseVersion } = useParams<{
          useCaseKey: string;
          useCaseVersion: string;
        }>();
        return (
          <Topics useCaseKey={useCaseKey!} useCaseVersion={useCaseVersion!} />
        );
      },
      Navigation: () => {
        const { url } = useRouteMatch();
        const { push } = useHistory();
        const { useCaseKey, useCaseVersion } = useParams<{
          useCaseKey: string;
          useCaseVersion: string;
        }>();
        return (
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              height: "100%"
            }}
          >
            <UseCaseNameAndVersion
              useCaseKey={useCaseKey!}
              useCaseVersion={useCaseVersion!}
            />
            <UseCaseVersionSelection
              onSelectVersion={version =>
                push(resolvePathname(`./${version}`, url))
              }
              useCaseKey={useCaseKey!}
            />
          </div>
        );
      },
      tab: 2
    },
    {
      title: "Use Case Data",
      path: `/use-cases/:useCaseKey/:useCaseVersion/data`,
      View: () => {
        const { useCaseKey, useCaseVersion } = useParams<{
          useCaseKey: string;
          useCaseVersion: string;
        }>();
        return (
          <Calculate
            useCaseKey={useCaseKey!}
            useCaseVersion={useCaseVersion!}
          />
        );
      },
      tab: 3
    },
    {
      title: "Use Case Data",
      path: `/use-cases/:useCaseKey/:useCaseVersion/data/:dataId`,
      View: () => {
        const { useCaseKey, useCaseVersion } = useParams<{
          useCaseKey: string;
          useCaseVersion: string;
        }>();
        return (
          <InputDataTableSet
            useCaseKey={useCaseKey!}
            useCaseVersion={useCaseVersion!}
          />
        );
      },
      Navigation: () => {
        const { useCaseKey, useCaseVersion, dataId } = useParams<{
          useCaseKey: string;
          useCaseVersion: string;
          dataId: string;
        }>();
        return (
          <UploadedDataName
            useCaseKey={useCaseKey!}
            useCaseVersion={useCaseVersion!}
            dataId={dataId!}
          />
        );
      },
      tab: 4
    },
    {
      title: "Upload Use Case Data",
      path: `/use-cases/:useCaseKey/:useCaseVersion/upload-data`,
      View: () => {
        const { useCaseKey, useCaseVersion } = useParams<{
          useCaseKey: string;
          useCaseVersion: string;
        }>();
        return (
          <UploadData
            useCaseKey={useCaseKey!}
            useCaseVersion={useCaseVersion!}
          />
        );
      },
      Navigation: () => (
        <div data-testid="upload-new-data-nav">Upload new data</div>
      ),
      tab: 3
    },
    {
      title: "Calculations",
      path: `/use-cases/:useCaseKey/:useCaseVersion/calculations`,
      View: () => {
        const { useCaseKey, useCaseVersion } = useParams<{
          useCaseKey: string;
          useCaseVersion: string;
        }>();
        const { url } = useRouteMatch();
        const { push } = useHistory();
        const navigateToCalculate = () =>
          push(createRoute(`/use-cases/${useCaseKey}/${useCaseVersion}/data`));
        const navigateToCalculation = (calculationId: string) =>
          push(`${url}/${calculationId}`);
        return (
          <AllJobsResultsWrapper
            useCaseKey={useCaseKey!}
            useCaseVersion={useCaseVersion!}
            navigateToCalculate={navigateToCalculate}
            navigateToCalculation={navigateToCalculation}
          />
        );
      },
      tab: 3
    },
    {
      title: "Public-API",
      path: `/use-cases/:useCaseKey/:useCaseVersion/public-api`,
      View: () => {
        const { useCaseKey, useCaseVersion } = useParams<{
          useCaseKey: string;
          useCaseVersion: string;
        }>();
        return (
          <PublicApi
            useCaseKey={useCaseKey!}
            useCaseVersion={useCaseVersion!}
          />
        );
      },
      Navigation: () => <div>Public API</div>,
      tab: 3
    },
    {
      title: "API-Tester",
      path: `/use-cases/:useCaseKey/:useCaseVersion/public-api/api-tester`,
      View: () => {
        const { useCaseKey, useCaseVersion } = useParams<{
          useCaseKey: string;
          useCaseVersion: string;
        }>();
        return useCaseKey && useCaseVersion ? (
          <Suspense fallback={<LinearProgress />}>
            <ApiTester
              useCaseKey={useCaseKey}
              useCaseVersion={useCaseVersion}
            />
          </Suspense>
        ) : null;
      },
      Navigation: () => <div>API Tester</div>,
      tab: 3
    },
    {
      title: "Calculation",
      path: `/use-cases/:useCaseKey/:useCaseVersion/calculations/:calculationId`,
      View: () => {
        const { useCaseKey, useCaseVersion, calculationId } = useParams<{
          useCaseKey: string;
          useCaseVersion: string;
          calculationId: string;
        }>();
        return (
          <JobResult
            useCaseKey={useCaseKey!}
            useCaseVersion={useCaseVersion!}
            calculationId={calculationId!}
          />
        );
      },
      Navigation: () => {
        const { useCaseKey, useCaseVersion, calculationId } = useParams<{
          useCaseKey: string;
          useCaseVersion: string;
          calculationId: string;
        }>();
        return (
          <CalculationName
            useCaseKey={useCaseKey!}
            useCaseVersion={useCaseVersion!}
            calculationId={calculationId!}
          />
        );
      },
      tab: 4
    },
    {
      title: "Input-Table",
      path: `/use-cases/:useCaseKey/:useCaseVersion/calculations/:calculationId/input-data/:tableId`,
      View: () => {
        const { useCaseKey, useCaseVersion, calculationId, tableId } =
          useParams<{
            useCaseKey: string;
            useCaseVersion: string;
            calculationId: string;
            tableId: string;
          }>();
        return (
          <CalculationResultInputTable
            useCaseKey={useCaseKey!}
            useCaseVersion={useCaseVersion!}
            calculationId={calculationId!}
            tableId={tableId!}
          />
        );
      },
      tab: 5
    },
    {
      title: "Output-Table",
      path: `/use-cases/:useCaseKey/:useCaseVersion/calculations/:calculationId/output-data/:tableId`,
      View: () => {
        const { useCaseKey, useCaseVersion, calculationId, tableId } =
          useParams<{
            useCaseKey: string;
            useCaseVersion: string;
            calculationId: string;
            tableId: string;
          }>();
        return (
          <CalculationResultOutputTable
            useCaseKey={useCaseKey!}
            useCaseVersion={useCaseVersion!}
            calculationId={calculationId!}
            tableId={tableId!}
          />
        );
      },
      tab: 5
    }
  ];
}

/**
 * Returns all routes.
 *
 * Transforms relative to absolute routes which is required for currently hosted dev builds.
 */
export default function getRoutes() {
  return getRelativeRoutes().map(({ path, title, View, Navigation, tab }) => ({
    title,
    path: `${process.env.PUBLIC_URL}${path}`,
    View,
    Navigation,
    tab
  }));
}
