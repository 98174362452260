import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import { makeStyles } from "@mui/styles";

import { Fragment } from "react";
import Logo from "./internal/Logo";
import NavigationActions from "./internal/NavigationActions";
import NavigationBreadCrumbs from "./internal/NavigationBreadCrumbs";
import UserGreeting from "./internal/UserGreeting";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    position: "relative",
    padding: 0,
    borderBottom: "1px solid #dfe6ed",
    backgroundColor: "#ffffff"
  },
  navBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: 0,
    flex: 1,
    margin: theme.spacing(1)
  }
}));

const CustomGreetings = ({
  signOut,
  authState,
  user
}: {
  signOut: () => void;
  authState: string;
  user: any;
}) => {
  const classNames = useStyles();
  const greeting =
    authState === "authenticated" ? (
      <UserGreeting
        signOut={signOut}
        name={user?.attributes?.name || ""}
        email={user?.attributes?.email || ""}
        familyName={user?.attributes?.family_name || ""}
      />
    ) : (
      <div>loading...</div>
    );
  return (
    <Fragment>
      <AppBar position="static">
        <Container className={classNames.container} maxWidth={false}>
          <Logo />
          {authState === "authenticated" && (
            <div className={classNames.navBar}>
              <NavigationBreadCrumbs />
              <NavigationActions greeting={greeting}></NavigationActions>
            </div>
          )}
        </Container>
      </AppBar>
    </Fragment>
  );
};

export default CustomGreetings;
