import { IconButton, Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Check, Close } from "@mui/icons-material";
import clsx from "clsx";
import { ScalingType } from "model/schema/globalTypes";
import { useState } from "react";
import EditField from "../EditField";
import {
  Workspace_projects_workspace_scaleInPolicy as ScaleInPolicy,
  Workspace_projects_workspace_scaleOutPolicy as ScaleOutPolicy
} from "../schema/Workspace";
import { useQueryWorkspace } from "../workspaceHooks";

const useStyles = makeStyles(() => ({
  center: {
    textAlign: "center"
  },
  left: {
    textAlign: "left"
  },
  nowrap: {
    whiteSpace: "nowrap"
  },
  rowStart: { gridRowStart: 3 },
  columnStart: { gridColumnStart: 2 }
}));

const ScalingPolicy = ({
  scaling,
  editable,
  onUpdate,
  workspaceId,
  min,
  max
}: {
  scaling: ScaleOutPolicy | ScaleInPolicy;
  editable: boolean;
  onUpdate?: (scaling: ScaleOutPolicy | ScaleInPolicy) => void;
  workspaceId: string;
  min: string;
  max?: string;
}) => {
  const css = useStyles();
  const [threshold, setThreshold] = useState<number>(scaling.threshold);
  const [scalingAdjustment, setScalingAdjustment] = useState<number>(
    scaling.scalingAdjustment
  );

  const { data: workspaceCapacity } = useQueryWorkspace({
    workspaceId
  });

  const capacity = workspaceCapacity?.projects.workspace?.capacity;
  const dirty =
    threshold !== scaling.threshold ||
    scalingAdjustment !== scaling.scalingAdjustment;

  return (
    <>
      <Typography className={clsx(css.nowrap, css.columnStart)}>
        {scaling.scalingType === ScalingType.ScaleOut
          ? "If less than"
          : "If more than"}
      </Typography>

      <EditField
        value={threshold}
        disabled={!editable}
        onChange={e => setThreshold(parseInt(e.target.value))}
        min={min}
        max={max}
      />
      <Typography className={clsx(css.nowrap)}>seat(s) are free</Typography>
      <Typography className={clsx(css.nowrap, css.left)}>
        {scaling.scalingType === ScalingType.ScaleOut ? ", add" : ", remove"}
      </Typography>
      <EditField
        value={scalingAdjustment}
        disabled={!editable}
        onChange={e => setScalingAdjustment(parseInt(e.target.value))}
        min="1"
        max={capacity?.maxCapacity.toString()}
      />
      <Typography className={clsx(css.nowrap)}>
        {scaling.scalingType === ScalingType.ScaleOut
          ? "new seat(s)"
          : "free seat(s)"}
      </Typography>

      <div className={clsx(css.center, css.nowrap)}>
        {dirty && (
          <>
            <Tooltip title="Save changes">
              <IconButton
                size="small"
                onClick={() => {
                  onUpdate &&
                    onUpdate({
                      ...scaling,
                      threshold,
                      scalingAdjustment
                    });
                }}
              >
                <Check />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel edit">
              <IconButton
                size="small"
                onClick={() => {
                  setThreshold(scaling.threshold);
                  setScalingAdjustment(scaling.scalingAdjustment);
                }}
              >
                <Close />
              </IconButton>
            </Tooltip>
          </>
        )}
      </div>
    </>
  );
};
export default ScalingPolicy;
