import { useQuery, gql } from "@apollo/client";
import { Rename } from "utils/typescript";
import {
  QueryUseCaseList,
  QueryUseCaseList_cloudPlatform_useCases_detail
} from "./schema/QueryUseCaseList";

export type UseCaseType = Rename<
  QueryUseCaseList_cloudPlatform_useCases_detail,
  "key",
  "useCaseKey"
>;

export const queryUseCaseList = gql`
  query QueryUseCaseList {
    cloudPlatform {
      useCases(latestVersionOnly: true) {
        detail {
          name
          image
          description
          tags
          key
          version
        }
      }
    }
  }
`;

export default function useQueryUseCaseList() {
  return useQuery<QueryUseCaseList>(queryUseCaseList);
}
