import { Avatar, Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles<Theme, UserAvatarProps>(theme => ({
  avatar: ({ large = false }) => ({
    color: "#3AA9AE",
    borderRadius: "0.75rem",
    backgroundColor: "#C9F7F5",
    fontFamily: "monospace",
    fontSize: large ? "24px" : undefined,
    width: large ? 50 : undefined,
    height: large ? 50 : undefined
  })
}));

export interface INameProvider {
  firstName: string;
  lastName: string;
}

export function createUserAvatarCaption(user: INameProvider): string {
  const shorthand =
    user.firstName.charAt(0).toUpperCase() +
    user.lastName.charAt(0).toUpperCase();
  return shorthand || "?";
}
type UserAvatarProps = {
  user: INameProvider;
  large?: boolean;
  dataTest?: string;
};

export default function UserAvatar(props: UserAvatarProps) {
  const { avatar } = useStyles(props);
  const { user, dataTest = "user" } = props;

  return (
    <Avatar data-test={dataTest} className={avatar}>
      {createUserAvatarCaption(user)}
    </Avatar>
  );
}
